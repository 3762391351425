/**
 * 加盟店側-マニュアル一覧画面
 * メモ：マニュアルを一覧表示
 */
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import clsx from "clsx";
import { Container, Button, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

import { useContainerStyles } from "../../../../templates/styles";
import { buttonStyles } from "../../../../common/components/styles";

import { IDocumentData } from "../../types";

import { GUEST_COLOR, FONT } from "../../../../../constants/common";
import { LATEST_DOCUMENTS_DATA } from "../../../../../constants/documents";

import { AuthContext } from "../../../../../cognito/AuthContext";
import { GlobalPopupContext } from "../../../../../App";
import getErrorMessage from "../../../../../utils/error";
import document from "../../../../../api/document";

import Dashboard from "../../../../templates/Dashboard";
import CustomBreadcrumbs from "../../../../common/components/molecules/CustomBreadcrumbs";

const useStyles = makeStyles({
  termLink: {
    textDecoration: "none",
  },
  button: {
    paddingLeft: 10,
    paddingBottom: 10,
    textTransform: "none",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
  updateAt: {
    width: 200,
    marginTop: 10,
    marginLeft: 20,
  },
  message: {
    fontFamily: FONT,
    textAlign: "center",
    fontSize: "1.0rem",
    marginTop: "40px",
    marginBottom: "50px",
  },
  infoIcon: {
    color: GUEST_COLOR.primary,
    verticalAlign: "top",
    fontSize: "1.3rem",
  },
  webManualButton: {
    paddingLeft: 10,
    paddingBottom: 10,
    fontSize: "12px",
    marginBottom: 10,
    textAlign: "center",
    color: "white",
    backgroundColor: "#3f51b5 !important",
    width: 550,
  },
});

const breadCrumbs = [{ name: "マニュアル", link: "" }];

const GuestDocumentListPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;

  const { authHeader } = useContext(AuthContext);
  const { setLoading } = useContext(GlobalPopupContext);
  const [latestDocuments, setLatestDocuments] = useState<IDocumentData[]>([]);
  const [errorMsg, setErrorMsg] = useState("");

  const containerClasses = useContainerStyles();
  const classes = useStyles();
  const buttonClasses = buttonStyles({
    width: 550,
    marginTop: 0,
    backgroundColor: GUEST_COLOR.primary,
  });

  const handleToTermsPdfPage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();

    const manualType = e.currentTarget.getAttribute("data-id");
    setLoading(true);
    // マニュアル取得APIを呼んでPDFデータを取得する
    document
      .get(authHeader as string, Number(manualType))
      .then((res) => {
        const pdf = res.data.result.preSignedUrl;
        history.push({
          pathname: `/guest/documents/pdf`,
          state: {
            pdfData: pdf,
          },
        });
      })
      .catch((error) => {
        setErrorMsg(getErrorMessage(error));
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const load = async () => {
      // マニュアル一覧取得API
      await document
        .getList(authHeader as string)
        .then((res) => {
          const manuals = res.data.result.manuals as IDocumentData[];
          const filteredDocuments = manuals.map((result) => {
            const copyDocument = LATEST_DOCUMENTS_DATA.find(
              (doc) =>
                doc.manualType.toString() === result.manualType.toString()
            );
            return { ...copyDocument, ...result };
          });
          setLatestDocuments(filteredDocuments);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMsg(getErrorMessage(error));
          setLoading(false);
        });
    };

    setErrorMsg("");
    setLoading(true);
    load();
    // 表示時のみなので
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboard
      title="マニュアル"
      color={GUEST_COLOR.base}
      backgroundColor={GUEST_COLOR.primary}
      iconName="terms"
    >
      <Container maxWidth="lg" className={containerClasses.container}>
        <CustomBreadcrumbs breadCrumbs={breadCrumbs} />
        {errorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMsg}
          </Alert>
        )}
        <div className={classes.buttonGroup}>
          <Button
            href="https://teachme.jp/27014/manuals/33329572"
            className={clsx(buttonClasses.button, classes.webManualButton)}
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
          >
            Webマニュアルはこちら
          </Button>
          <span className={classes.updateAt} />
        </div>

        {latestDocuments.length > 0 ? (
          latestDocuments.map((doc) => (
            <div className={classes.buttonGroup} key={doc.name}>
              {/* マニュアルボタン */}
              <Button
                type="button"
                className={clsx(buttonClasses.button, classes.button)}
                data-id={doc.manualType}
                onClick={handleToTermsPdfPage}
                variant="contained"
              >
                {`${doc.name}`} {doc.version && `Ver.${doc.version}`}
              </Button>
              {/* 更新日 */}
              <span className={classes.updateAt}>
                {doc.updatedAt && `更新日: ${doc.updatedAt}`}
              </span>
            </div>
          ))
        ) : (
          <div className={clsx(classes.message)}>
            <InfoIcon className={classes.infoIcon} />
            該当するマニュアルはありません。
          </div>
        )}
      </Container>
    </Dashboard>
  );
};

export default withRouter(GuestDocumentListPage);
